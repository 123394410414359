<template>
<!-- 班级管理页面 -->
    <div>
        <h2>班级管理页面</h2>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    }
}
</script>
 
<style lang="less" scoped>
    
</style>